import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入公共样式
import '@/assets/css/allstyle.css'
import '@/assets/css/react.css'

// 引入路由
import router from './router';
Vue.use(router)
// 引入axios
import requests from './utils/request'
import common from './utils/common';

// 引入地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'Hr0cqxXmhtVXTeeYUN7y3fmNM9j8brNO'
})

Vue.prototype.requests = requests;
Vue.prototype.common = common;
 
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
	el:'#app',
	render: h => h(App),
  router,
	// beforeCreate中模板未解析，且this是vm
	beforeCreate(){
		Vue.prototype.$bus = this	//安装全局事件总线
	}
})
