<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'

export default {
  name: 'App',
  components: {
    Header,Footer
  },
  mounted(){
    let pagewidth = window.innerWidth;
    // console.log(pagewidth)
    // sessionStorage.setItem(pagewidth,'pagewidth')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
    /* width: 2048px; */
    margin: 0 auto;
}
</style>
