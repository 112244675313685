<template>
  <div class="container">
    <div class="box">
      <el-image
        fit="contain"
        :src="require('@/assets/images/footer/footer.png')"
      ></el-image>
      <div class="fengexian"></div>
      <div class="right">
        <ul>
          <li>{{ wangzhanList[0] }}</li>
          <li>
            <a
              target="_blank"
              class="anbei"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003248"
              ><img
                src="@/assets/images/footer/beian.png"
                style="float: left;width: 12px;margin-right: 6px;"
              />
              <p>豫公网安备 41010702003248号</p></a
            >
            <a href="http://www.beianx.cn/" target="_blank">{{
              wangzhanList[1]
            }}</a>
          </li>
          <li>{{ wangzhanList[2] }}&nbsp;&nbsp;&nbsp;&nbsp;{{ wangzhanList[3] }}</li>
        </ul>
        <div>
          <a href="http://www.beianx.cn/" target="_blank"
            ><el-image
              fit="contain"
              :src="require('@/assets/images/footer/tubiao.png')"
            ></el-image
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      wangzhanList: [
        "河南省科学院集成电路研究所 版权所有",
        "豫ICP备2022027546号-1",
        "地址：河南省郑州市金水区明理路266-38号",
        "邮编：450046",
      ],
    };
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .container {
    margin-top: 30px;
    width: 100%;
    background: #003e9f;

    .box {
      width: 1380px;
      height: 160px;
      padding: 20px 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
    }

    .el-image {
      opacity: 1;
      transition: unset;
      width: 50%;
    }

    .fengexian {
      position: absolute;
      left: 51%;
      width: 2px;
      height: 118px;
      background-color: #ccc;
    }

    .right {
      position: absolute;
      right: 0;
      width: 46%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 30px;
      ul {
        li {
          font-size: 16px;
          text-align: left;
          color: #fff;
          margin-top: 10px;
          display: flex;
          align-items: center;
          &:nth-child(2) {
            display: flex;
            .anbei {
              margin-right: 10px;
              display: flex;
              align-items: center;
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            font-size: 12px;
          }
          a {
            text-decoration: none;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .el-image {
        width: 80px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .container {
    // margin-top: 20px;
    width: 100%;

    .box {
      width: 100%;
      height: 120px;
      padding: 20px 0;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .el-image {
      opacity: 1;
      transition: unset;
      width: 100%;
      background: #003e9f;
    }

    .fengexian {
      display: none;
      width: 2px;
      height: 118px;
      background-color: #ccc;
    }

    .right {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 0 30px;
      background: #003e9f;
      ul {
        li {
          font-size: 14px;
          text-align: left;
          color: #fff;
          margin-top: 10px;
          line-height: 24px;
          a {
            text-decoration: none;
            color: #fff;
            &:hover {
              color: #003e9f;
            }
          }
          &:nth-child(2) {
            display: flex;
            flex-wrap: wrap;
            .anbei {
              margin-right: 10px;
              display: flex;
              align-items: center;
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            font-size: 12px;
          }
        }
      }
      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-image {
          margin: 20px 0;
          width: 55px;
        }
      }
    }
  }
}
</style>
