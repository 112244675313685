import requests from "./request"
// 获取所有菜单
const MenuList = function () {
    let res = requests.get('menu').then(res => {
        if (res.code == '0') {
            return res.data
        } else {
            return []
        }
    })
    return res
}

// 获取某一个一级菜单
const subMenuList = function (id) {
    let res = this.MenuList().then(res => {
        // console.log(res)
        let result = []
        result = res.filter(item => {
            return item.cateogryId == id
        })
        if (result.length == 0) {
            res.forEach(item => {
                // console.log(item)
                item.childs.filter(item1 => {
                    if (item1.cateogryId == id) {
                        // console.log(item1.cateogryId == id)
                        result.push(item) 
                    }
                })
            })
        }
        return result
    })
    return res
}

export default {
    MenuList,
    subMenuList
}