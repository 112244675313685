import { resolve } from "path-browserify";
import Vue from "vue";
import Router from "vue-router";


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);
const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    // 首页
    {
      path: "/",
      name: "home",
      component: resolve => require(['@/views/home/home.vue'], resolve)
    },

    {
      path: "/jigougaikuang",
      name: "jigougaikuang",
      component: resolve => require(['@/views/jigou/index.vue'], resolve)
    },
    {
      path: "/jigougaikuang/yanjiusuanjianjie/xiangqing/:idhref",
      name: "yanjiusuanjianjie",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/jigougaikuang/lingdaojiti/xiangqing/:idhref",
      name: "lingdaojiti",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/jigougaikuang/zhuzhijigou/xiangqing/:idhref",
      name: "zhuzhijigou",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },

    {
      path: "/xinwenzixun",
      name: "xinwenzixun",
      component: resolve => require(['@/views/news/index.vue'], resolve),
    },
    {
      path:'/xinwenzixun/xinwen/xiangqing/:idhref',
      name:'xinwen',
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path:'/xinwenzixun/xinwen/liebiao/:idhref',
      name:'xinwenliebiao',
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path:'/xinwenzixun/zixun/xiangqing/:idhref',
      name:'zixun',
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path:'/xinwenzixun/zixun/liebiao/:idhref',
      name:'zixunliebiao',
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },

    {
      path: "/kexueyanjiu",
      name: "kexueyanjiu",
      component: resolve => require(['@/views/keyan/index.vue'], resolve)
    },
    {
      path: "/kexueyanjiu/keyanfangxiang/xiangqing/:idhref",
      name: "keyanfangxiang",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/kexueyanjiu/keyanfangxiang/liebiao/:idhref",
      name: "keyanfangxiangliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/kexueyanjiu/keyandongtai/xiangqing/:idhref",
      name: "keyandongtai",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/kexueyanjiu/keyandongtai/liebiao/:idhref",
      name: "keyandongtailiebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/rencaiduiwu",
      name: "rencaiduiwu",
      component: resolve => require(['@/views/rencai/index.vue'], resolve)
    },
    {
      path: "/rencaiduiwu/rencaiduiwu/xiangqing/:idhref",
      name: "rencaiduiwurencaiduiwu",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/rencaiduiwu/rencaiduiwu/liebiao/:idhref",
      name: "rencaiduiwurencaiduiwuliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/rencaiduiwu/chengpinyingcai/xiangqing/:idhref",
      name: "rencaiduchengpinyingcaiuiwu",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/rencaiduiwu/chengpinyingcai/liebiao/:idhref",
      name: "rencaiduchengpinyingcaiuiwuliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },

    {
      path: "/dangqunyuandi",
      name: "dangqunyuandi",
      component: resolve => require(['@/views/dangqun/index.vue'], resolve)
    },
    {
      path: "/dangqunyuandi/dangqundongtai/xiangqing/:idhref",
      name: "dangqundongtai",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/dangqunyuandi/dangqundongtai/liebiao/:idhref",
      name: "dangqundongtailiebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/dangqunyuandi/quntuangongzuo/xiangqing/:idhref",
      name: "quntuangongzuo",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/dangqunyuandi/quntuangongzuo/liebiao/:idhref",
      name: "quntuangongzuoliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/dangqunyuandi/xuexizhinan/xiangqing/:idhref",
      name: "xuexizhinan",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/dangqunyuandi/xuexizhinan/liebiao/:idhref",
      name: "xuexizhinanliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },

    {
      path: "/kexuechuanbo",
      name: "kexuechuanbo",
      component: resolve => require(['@/views/kexue/index.vue'], resolve)
    },
    {
      path: "/kexuechuanbo/kepowenzhang/xiangqing/:idhref",
      name: "kepowenzhang",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/kexuechuanbo/kepowenzhang/liebiao/:idhref",
      name: "kepowenzhangliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/kexuechuanbo/kepushipin/xiangqing/:idhref",
      name: "kepushipin",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/kexuechuanbo/kepushipin/liebiao/:idhref",
      name: "kepushipinliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },

    {
      path: "/xinxigongkai",
      name: "xinxigongkai",
      component: resolve => require(['@/views/xinxi/index.vue'], resolve)
    },
    {
      path: "/xinxigongkai/tongzhigonggao/xiangqing/:idhref",
      name: "tongzhigonggao",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/xinxigongkai/tongzhigonggao/liebiao/:idhref",
      name: "tongzhigonggaoliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/xinxigongkai/zhaobiaocaigou/xiangqing/:idhref",
      name: "zhaobiaocaigou",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/xinxigongkai/zhaobiaocaigou/liebiao/:idhref",
      name: "zhaobiaocaigouliebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/fengcaizhanshi/xiangqing/:idhref",
      name: "fengcaizhanshi",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    
    {
      path: "/liebiao",
      name: "liebiao",
      component: resolve => require(['@/views/liebiao/index.vue'], resolve)
    },
    {
      path: "/xiangqing",
      name: "xiangqing",
      component: resolve => require(['@/views/xiangqing/index.vue'], resolve)
    },
    {
      path: "/zhongzhuan",
      name: "zhongzhuan",
      component: resolve => require(['@/views/zhongzhuan/index.vue'], resolve)
    },
    {
      path: "/yuedu",
      name: "yuedu",
      component: resolve => require(['@/views/yuedu/index.vue'], resolve)
    },
  ]
});
export default router;
