// 引入axios
import axios from 'axios'

const requests = axios.create({
        // baseURL会自动地加载到接口地址上
    baseURL: "https://hniic.com/sysapi/webapi/",//http://hniic.com/sysapi/webapi/
        // 指定请求超时的毫秒数，请求花费的时间超过timeout，请求则会被中断
    timeout: 6000,
        // `withCredentials` 表示跨域请求时是否需要使用凭证
    withCredentials: true, // default,
        // headers是即将被发送的自定义请求头
    headers: {
        'Content-type': 'application/json, charset=utf-8'
    },
})

// 添加请求拦截器
requests.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    const token = sessionStorage.getItem("token")
    if(token) {
      config.headers.token = token
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
requests.interceptors.response.use((res) => {
    // 对响应数据做点什么
    return res.data;
  }, (error) => {
    
    // 对响应错误做点什么
    return Promise.reject(error);
  });

export default requests
