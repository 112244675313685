<template>
  <div class="container">
    <div class="header1">
      <div class="link">
        <a href="http://www.hnskxy.com/" target="_blank">河南省科学院</a>
        <span>/</span>
        <a href="#" @click="toXiangqing(8, 9)">联系我们</a>
        <!-- <span>/</span>
                    <a href="#">网站地图</a> -->
        <!-- <div class="search">
          <el-input v-model="search" placeholder="搜索"></el-input>
          <i class="el-icon-search" style="cursor: pointer;"></i>
        </div> -->
      </div>
    </div>
    <div class="header">
      <div class="pc">
        <el-image class="beijing" :src="require('@/assets/images/beijing.png')"></el-image>
        <div class="img">
          <el-image :src="require('@/assets/images/logo.png')"></el-image>
        </div>
      </div>
      <div class="app">
        <el-image class="applogo" :src="require('@/assets/images/header/applogo.png')"></el-image>
        <div class="headernav">
          <!-- <el-image
            :src="require('@/assets/images/header/appPc.png')"
          ></el-image> -->
          <!-- <el-image v-if="!isSearch" :src="require('@/assets/images/header/appSearch.png')" style="cursor: pointer;"
            @click="searchChange"></el-image>
          <el-image v-else :src="require('@/assets/images/header/close.png')" @click="searchChange"></el-image> -->
        </div>
        <!-- <div class="appsearch" v-if="isSearch">
          <div class="appsearchbox">
            <el-input v-model="search" placeholder="搜索"></el-input>
            <i class="el-icon-search"></i>
          </div>
        </div> -->
      </div>
    </div>
    <div class="nav" :style="'display:' + isShow ? '' : 'none'">
      <ul class="naBar">
        <li class="mo" :style="'width:' + `calc(100%/${nav.length})`" v-for="(item, idx) in nav"
          @click="goIndex(item.cateogryId)" :key="idx" @mouseenter="twomenu(idx)" @mouseleave="hidemenu">
          <span v-if="item.name">{{ item.name }}</span>
          <!-- 静态的 -->
          <!-- <ul :style="index == idx ? '' : 'display: none;'" v-if="item.childs.length > 0">
                        <li v-for="(items, idxs) in item.childs" :key="idxs" @click="tiao(idxs)">{{ items.name }}</li>
                    </ul> -->
          <ul :style="index == idx ? '' : 'display: none;'" v-if="item.childs.length > 0">
            <li v-for="(items, idxs) in item.childs" :key="idxs" @click.stop="tiao(item.cateogryId, idxs)">
              {{ items.name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      search: "",
      isSearch: false,
      isShow: false,
      // nav: [
      //   { name: "首页", childs: [] },
      //   {
      //     name: "机构概况",
      //     childs: [
      //       { name: "研究所简介"},
      //       { name: "领导集体"},
      //       { name: "组织机构"},
      //       { name: "联系我们"},
      //     ],
      //   },
      //   {
      //     name: "新闻中心",
      //     childs: [
      //       { name: "时政要闻"},
      //       { name: "行业新闻"},
      //       { name: "所内新闻"},
      //       { name: "电子所刊"},
      //       { name: "科学院要闻"},
      //     ],
      //   },
      //   {
      //     name: "科研工作",
      //     childs: [
      //       { name: "科研方向"},
      //       { name: "成果转化"},
      //     ],
      //   },
      //   {
      //     name: "人才队伍",
      //     childs: [
      //       { name: "人才队伍"},
      //       { name: "诚聘英才"},
      //     ],
      //   },
      //   {
      //     name: "党群园地",
      //     childs: [
      //       { name: "党群动态"},
      //       { name: "群团工作"},
      //       { name: "学习指南"},
      //     ],
      //   },
      //   {
      //     name: "科学传播",
      //     childs: [
      //       { name: "科普文章"},
      //       { name: "科普视频"},
      //     ],
      //   },
      //   {
      //     name: "信息公开",
      //     childs: [
      //       { name: "通知公告"},
      //       { name: "采购信息"},
      //     ],
      //   },
      // ],
      nav: [],
      index: null, //移入导航栏的索引值
      routerIndex: 0, //记录当前页面的索引值
      ziIndex: 0, //记录子页面的索引值
    };
  },
  beforeMount() {
    this.getMenu();
  },
  mounted() {
    this.isShow = true;
  },
  methods: {
    // 手机端搜索栏切换
    searchChange() {
      this.isSearch = !this.isSearch;
    },
    // 获取菜单
    getMenu() {
      let that = this;

      // 2023-11-04：为防止首次进入页面时菜单加载慢导致的出现啊菜单空屏，先写一份静态数据，待获取到菜单数据后进行覆盖
      this.nav = [
        {
          "cateogryId": 1,
          "parentId": 0,
          "name": "新闻资讯",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2023-02-21 12:16:55",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 2,
              "parentId": 1,
              "name": "新闻",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-13 18:40:11",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 3,
              "parentId": 1,
              "name": "行业新闻",
              "orderid": 2,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:01",
              "updateBy": "admin",
              "updateTime": "2023-02-13 18:37:37",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 0,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 4,
              "parentId": 1,
              "name": "资讯",
              "orderid": 4,
              "icon": "",
              "createBy": "admin",
              "createTime": "2023-01-08 19:00:21",
              "updateBy": "admin",
              "updateTime": "2023-02-13 19:36:02",
              "style": 0,
              "isReply": 0,
              "subUrl": "123",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 5,
              "parentId": 1,
              "name": "电子所刊",
              "orderid": 5,
              "icon": "",
              "createBy": "admin",
              "createTime": "2023-01-08 19:01:10",
              "updateBy": "admin",
              "updateTime": "2023-02-06 15:04:46",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 0,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        },
        {
          "cateogryId": 6,
          "parentId": 0,
          "name": "研究所简介",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2019-09-30 14:49:17",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 7,
              "parentId": 6,
              "name": "组织机构",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2019-09-30 14:49:17",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 8,
              "parentId": 6,
              "name": "联系我们",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2020-01-07 14:56:27",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 31,
              "parentId": 6,
              "name": "领导集体",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2001-01-01 00:00:00",
              "updateBy": "admin",
              "updateTime": "2023-02-16 18:17:18",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        },
        {
          "cateogryId": 9,
          "parentId": 0,
          "name": "科学传播",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2023-02-06 18:54:07",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 10,
              "parentId": 9,
              "name": "科普文章",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-13 18:42:44",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 11,
              "parentId": 9,
              "name": "科普视频",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-13 18:42:37",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        },
        {
          "cateogryId": 12,
          "parentId": 0,
          "name": "科学研究",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2023-02-06 18:58:04",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 13,
              "parentId": 12,
              "name": "科研方向",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-06 18:40:03",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 34,
              "parentId": 12,
              "name": "科研动态",
              "orderid": 0,
              "icon": "",
              "createBy": "admin",
              "createTime": "2023-02-08 16:42:17",
              "updateBy": "admin",
              "updateTime": "2023-02-13 19:31:03",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        },
        {
          "cateogryId": 16,
          "parentId": 0,
          "name": "人才队伍",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2023-03-09 09:04:35",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 17,
              "parentId": 16,
              "name": "人才队伍",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-03-09 09:04:37",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 0,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 26,
              "parentId": 16,
              "name": "诚聘英才",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-13 18:41:29",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        },
        {
          "cateogryId": 18,
          "parentId": 0,
          "name": "党群园地",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2019-09-30 14:49:17",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 19,
              "parentId": 18,
              "name": "党群动态",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-13 19:35:51",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 20,
              "parentId": 18,
              "name": "群团工作",
              "orderid": 2,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-08 09:33:56",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 0,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 21,
              "parentId": 18,
              "name": "学习指南",
              "orderid": 3,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-01-29 10:39:19",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        },
        {
          "cateogryId": 22,
          "parentId": 0,
          "name": "信息公开",
          "orderid": 1,
          "icon": "",
          "createBy": "管理员",
          "createTime": "2019-09-30 14:49:17",
          "updateBy": "admin",
          "updateTime": "2023-02-06 18:56:38",
          "style": 0,
          "isReply": 0,
          "subUrl": "",
          "type": 0,
          "isState": 1,
          "isShowSearch": 1,
          "childs": [
            {
              "cateogryId": 23,
              "parentId": 22,
              "name": "通知公告",
              "orderid": 1,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-06 17:59:55",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            },
            {
              "cateogryId": 24,
              "parentId": 22,
              "name": "招标采购",
              "orderid": 2,
              "icon": "",
              "createBy": "管理员",
              "createTime": "2019-09-30 14:49:17",
              "updateBy": "admin",
              "updateTime": "2023-02-10 15:36:58",
              "style": 0,
              "isReply": 0,
              "subUrl": "",
              "type": 0,
              "isState": 1,
              "isShowSearch": 1,
              "childs": null
            }
          ]
        }
      ]

      that.requests.get("menu").then((res) => {
        // console.log(res)
        if (res.code == "0") {
          if (res.data.length != 0) {
            let menuData = [
              { name: "首页", cateogryId: -1, childs: [] },
              {
                name: "机构概况",
                cateogryId: 6,
                childs: [
                  { name: "研究所简介" },
                  { name: "领导集体" },
                  { name: "组织机构" },
                  // { name: "联系我们" },
                ],
              },
            ];
            res.data.forEach((item) => {
              // 去除cateogryId==6的一级菜单
              if (
                item.cateogryId != 6 &&
                item.isState == 1
              ) {
                menuData.push(item);
                item.childs = item.childs.filter((v) => v.isState == 1);
              }
              if (item.cateogryId == 6 && item.isState == 1) {
                // console.log(item)
                item.childs.forEach((item1) => {
                  if (item1.cateogryId == 31 && item1.isState != 1) {
                    menuData[1].childs = menuData[1].childs.filter(
                      (v) => v.name != "领导集体"
                    );
                  }
                });
              }
            });
            this.nav = menuData;
            // switch (item.cateogryId) {
            //   //   case 1:
            //   //     that.nav[2].childs = item.childs
            //   //     break;
            //   //   case 12:
            //   //     that.nav[3].childs = item.childs
            //   //     break;
            //   //   case 16:
            //   //     that.nav[4].childs = item.childs
            //   //     break;
            //   //   case 18:
            //   //     that.nav[5].childs = item.childs
            //   //     break;
            //   //   case 9:
            //   //     that.nav[6].childs = item.childs
            //   //     break;
            //   //   case 22:
            //   //     that.nav[7].childs = item.childs
            //   //     break;
            //   // }
            // });
          }
        }
      });
    },
    // 去详情
    toXiangqing(categoryId, newsId) {
      let path = ''
      let liebiaoid = categoryId
      if (typeof liebiaoid != 'string') {
        liebiaoid = liebiaoid.toString()
      }
      let htmlfile = "/doc_" + newsId + ".html";
      switch (liebiaoid) {
        case '2':
          path = '/xinwenzixun/xinwen/xiangqing' + htmlfile
          break;
        case '4':
          path = '/xinwenzixun/zixun/xiangqing' + htmlfile
          break;
        case '13':
          path = '/kexueyanjiu/keyanfangxiang/xiangqing' + htmlfile
          break;
        case '34':
          path = '/kexueyanjiu/keyandongtai/xiangqing' + htmlfile
          break;
        case '17':
          path = '/rencaiduiwu/rencaiduiwu/xiangqing' + htmlfile
          break;
        case '26':
          path = '/rencaiduiwu/chengpinyingcai/xiangqing' + htmlfile
          break;
        case '19':
          path = '/dangqunyuandi/dangqundongtai/xiangqing' + htmlfile
          break;
        case '20':
          path = '/dangqunyuandi/quntuangongzuo/xiangqing' + htmlfile
          break;
        case '21':
          path = '/dangqunyuandi/xuexizhinan/xiangqing' + htmlfile
          break;
        case '10':
          path = '/kexuechuanbo/kepowenzhang/xiangqing' + htmlfile
          break;
        case '11':
          path = '/kexuechuanbo/kepushipin/xiangqing' + htmlfile
          break;
        case '23':
          path = '/xinxigongkai/tongzhigonggao/xiangqing' + htmlfile
          break;
        case '24':
          path = '/xinxigongkai/zhaobiaocaigou/xiangqing' + htmlfile
          break;
        default:
          break;
      }
      sessionStorage.setItem("categoryId", categoryId);
      const { href } = this.$router.resolve({
        path: path,
        // query: {
        //   id: newsId,
        // },
      });
      window.open(href, "_blank");
    },
    cancle() { },
    // 移入时
    twomenu(index) {
      this.index = index;
    },
    // 移出时
    hidemenu() {
      this.index = null;
    },
    // 跳转到一级列表
    goIndex(id, index) {
      // console.log(id, index);
      // console.log(this.routerIndex);
      if (index) {
        sessionStorage.setItem("index", index);
      } else {
        sessionStorage.removeItem("index");
      }
      this.routerIndex = sessionStorage.getItem("routerIndex");
      // console.log(typeof this.routerIndex, this.routerIndex, typeof id, id);

      // if (this.routerIndex == id) {
      //   this.$router.go(0);
      //   return false;
      // }


      // if (this.routerIndex != 0) {
      //     if (this.routerIndex == id) {
      //         this.routerIndex = sessionStorage.getItem('routerIndex')
      //         let name = id == 0 ? '/' : id == 1 ? '/jigou' : id == 2 ? '/news' : id == 3 ? '/keyan' : id == 4 ? '/rencai' : id == 5 ? '/dangqun' : id == 6 ? '/kexue' : '/xinxi'
      //         // console.log(name, index)
      //         this.$router.push({ path: '/zhongzhuan', query: { name: name} })
      //         sessionStorage.setItem('routerIndex', id)
      //         return false
      //         // this.$router.go(0)
      //         // return false
      //     }
      // }
      //跳转到相应的路由
      if (id == -1) {
        this.$router.push("/");
      }
      if (id == 6) {
        this.$router.push({ path: "/jigougaikuang" });
      }
      if (id == 1) {
        this.$router.push({ path: "/xinwenzixun" });
      }
      if (id == 12) {
        this.$router.push({ path: "/kexueyanjiu" });
      }
      if (id == 16) {
        this.$router.push({ path: "/rencaiduiwu" });
      }
      if (id == 18) {
        console.log(id, index);
        this.$router.push({ path: "/dangqunyuandi" });
      }
      if (id == 9) {
        console.log(id, index);
        this.$router.push({ path: "/kexuechuanbo" });
      }
      if (id == 22) {
        this.$router.push({ path: "/xinxigongkai" });
      }

      sessionStorage.setItem("routerIndex", id);
    },
    // 跳转到二级列表
    tiao(firstIndex, secondIndex) {
      console.log(firstIndex, secondIndex);
      this.goIndex(firstIndex, secondIndex);
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .container {
    .header1 {
      width: 100%;
      // padding: 3px 0;
      line-height: 44px;
      background: #f3f4fa;
      display: flex;
      justify-content: center;

      .link {
        width: 1380px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;

        a {
          font-size: 14px;
          text-decoration: none;
          color: #b8b8bb;
        }

        span {
          margin: 0 12px;
          font-size: 16px;
          color: #b8b8bb;
        }

        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 180px;
          height: 30px;
          border-radius: 16px;
          padding: 0 8px 0 16px;
          background-color: #6671b2;
          margin-left: 24px;

          .el-input {
            height: 30px;
            line-height: 30px;
          }

          /deep/.el-input__inner {
            height: 30px;
            line-height: 30px;
            background-color: #6671b2;
            border: 0;
            color: #fff;
            padding: 0;
            border-radius: 0;

            &::placeholder {
              color: #fff;
            }
          }

          i {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }

    .header {
      width: 100%;
      position: relative;
      padding: 20px 0;

      .pc {
        .beijing {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .img {
          width: 1380px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .el-image {
            overflow: hidden;
            opacity: 1;
            transition: unset;
            width: 600px;
            height: 100px;
          }
        }
      }

      .app {
        display: none;
      }
    }

    .nav {
      width: 100%;
      min-width: 1380px;
      height: 55px;
      background-color: #3d55a7;

      .naBar {
        width: 1380px;
        margin: 0 auto;
        overflow: hidden;

        li.mo {
          float: left;
          // width: calc(100%/8);
          line-height: 55px;
          cursor: pointer;
          transition: all 0.5s;

          &:hover {
            background: #2a69cf;
            cursor: pointer;
          }

          span {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 55px;
            font-size: 18px;
            font-weight: 900;
            color: #ffffff;
          }

          ul {
            width: 100%;
            position: relative;
            z-index: 999;
            background: #3d55a7;

            li {
              width: 100%;
              text-align: center;
              font-size: 18px;
              font-weight: 900;
              line-height: 55px;
              color: #fff;
              transition: all 0.3s;
            }

            li:hover {
              background: #2a69cf;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .container {
    .header1 {
      width: 100%;
      background: #e5ecf4;
      display: flex;
      justify-content: center;
      display: none;

      .link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
          font-size: 12px;
          text-decoration: none;
          color: gray;
        }

        span {
          margin: 0 5px;
          font-size: 16px;
        }
      }
    }

    .header {
      width: 100%;
      min-width: 300px;
      position: relative;
      padding-top: 15px;

      .beijing {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .pc {
        display: none;
      }

      .app {
        display: flex;
        justify-content: flex-start;
        position: relative;

        .applogo {
          width: 160%;
        }

        .headernav {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 25%;

          .el-image {
            width: 80%;
          }
        }

        .appsearch {
          transition: all 0.5s;
          position: absolute;
          bottom: -55px;
          width: 100%;
          height: 55px;
          background: #3d55a7;
          box-sizing: border-box;
          padding: 8px 20px;
          display: flex;
          z-index: 10;

          .appsearchbox {
            width: 100%;
            position: relative;

            .el-input {
              width: 100%;
              height: 40px;
              line-height: 40px;
            }

            /deep/.el-input__inner {
              height: 40px;
              line-height: 40px;
              background-color: #6671b2;
              border: 0;
              color: #fff;
              padding-left: 20px;
              border-radius: 20px;
              font-size: 18px;

              &::placeholder {
                color: #fff;
              }
            }

            i {
              position: absolute;
              top: 50%;
              margin-top: -12px;
              right: 10px;
              font-size: 24px;
              color: #fff;
            }
          }
        }
      }
    }

    .nav {
      width: 100%;
      // min-width: 300px;
      background-color: #3d55a7;

      .naBar {
        width: 100%;
        display: flex;
        overflow-y: auto;
        margin: 0 auto;

        li.mo {
          float: left;
          width: 25% !important;
          min-width: 90px;
          line-height: 55px;
          cursor: pointer;
          transition: all 0.5s;

          span {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            // line-height: 50px;
            font-size: 18px;
            font-weight: 900;
            color: #ffffff;
          }

          ul {
            width: 100%;
            position: relative;
            z-index: 999;
            background: #3d55a7;
            display: none !important;

            li {
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-weight: 900;
              color: #fff;
              transition: all 0.3s;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="less">
.nav ul li.navClect {
  background: rgba(255, 255, 255, 1);
}

.nav ul li.navClect span {
  color: #e7390a;
}
</style>